




























































import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "NewCategoryResponsive",
})

export default class NewCategoryResponsive extends Vue {

}
